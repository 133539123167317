<template>
  <div>
    <!-- 与操作按钮相分隔开的内容 -->
    <div>
      <!-- 基础信息 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">基础信息</div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">客户姓名:</span>
            <span>{{ taskDetail.contacts }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单状态:</span>
            <span>{{ taskDetail.states }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单号:</span>
            <span>{{ taskDetail.order_id }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">店铺:</span>
            <span>{{ taskDetail.store_name }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">负责店长:</span>
            <span>{{ taskDetail.username }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">下单时间:</span>
            <span>{{ taskDetail.created_time }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon">
            <span class="childMt">联系电话:</span>
            <span>{{ taskDetail.telephone }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">订单来源:</span>
            <span
              v-if="
                taskDetail.channelTitle == null || taskDetail.channelTitle == ''
              "
              >{{ taskDetail.channel }}</span
            >
            <span v-else
              >{{ taskDetail.channel }}-{{ taskDetail.channelTitle }}</span
            >
          </div>
          <div class="childCon">
            <span class="childMt">房屋问题:</span>
            <span>{{ taskDetail.problemTitle }}</span>
          </div>
          <div class="childCon">
            <span class="childMt">添加微信:</span>
            <span v-if="taskDetail.wechat == 1">是</span>
            <span v-else>否</span>
          </div>
          <div class="childCon2">
            <span class="childMt">维修地址:</span>
            <span>{{ taskDetail.addres }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon3">
            <span class="childMt">备注信息:</span>
            <span>{{ taskDetail.remarks }}</span>
          </div>
        </div>
      </div>
      <!-- 投诉售后任务 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
        "
      >
        <div style="font-size: 20px; margin: 8px 0 20px 0">投诉售后任务</div>
        <div class="prCon">
          <div class="childCon4">
            <span class="childMt">投诉时间:</span>
            <span>{{ taskDetail1.time }}</span>
          </div>
          <div class="childCon4">
            <span class="childMt">类型:</span>
            <span>{{ taskDetail1.role }}</span>
          </div>
          <div class="childCon4">
            <span class="childMt">投诉对象:</span>
            <span
              v-if="
                taskDetail1.shopusername == null &&
                taskDetail1.masterworker == null
              "
            ></span>
            <span
              v-if="
                taskDetail1.shopusername != null &&
                taskDetail1.masterworker == null
              "
              >{{ taskDetail1.shopusername }}</span
            >
            <span
              v-if="
                taskDetail1.masterworker == null &&
                taskDetail1.masterworker != null
              "
              >{{ taskDetail1.masterworker }}</span
            >
            <span v-else
              >{{ taskDetail1.shopusername }}-{{
                taskDetail1.masterworker
              }}</span
            >
          </div>
          <div class="childCon4">
            <span class="childMt">创建人:</span>
            <span v-if="taskDetail1.role == '客户创建'">客户创建</span>
            <span v-else>{{ taskDetail1.username }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childCon5">
            <span class="childMt">投诉原因:</span>
            <span>{{ taskDetail1.reason }}</span>
          </div>
          <div class="childCon4">
            <span class="childMt">客户诉求:</span>
            <span>{{ taskDetail1.requirement }}</span>
          </div>
        </div>
        <div class="prCon">
          <div class="childMt">图片:</div>
          <div v-if="imgArr == 0" style="display: flex" class="childCon4">
            暂无图片
          </div>
          <div v-else class="flex_start">
            <div v-for="(item, index) in taskDetail1.picture" :key="index">
              <img
                :src="item"
                style="width: 100px; height: 100px; margin-left: 5px"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- 处理记录 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
          margin-bottom: 10px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px">处理记录</div>
        <avue-crud :data="dealRecord" :option="option2">
          <template slot-scope="scope" slot="connect">
            <span v-if="scope.row.connect == 0">是</span>
            <span v-if="scope.row.connect == 2">否</span>
          </template>
          <template slot-scope="scope" slot="complete">
            <span v-if="scope.row.complete == 1">已完成</span>
            <span v-if="scope.row.complete == 2">未完成</span>
          </template>
        </avue-crud>
      </div>
      <!-- 沟通记录表格 -->
      <div
        style="
          border: 1px solid #ddd;
          margin-top: 20px;
          padding: 0 20px;
          color: #666;
          font-size: 15px;
          width: 100%;
          margin-bottom: -80px;
        "
      >
        <div style="font-size: 20px; margin-top: 8px">沟通记录</div>
        <avue-crud :data="connectRecord" :option="option1">
          <template slot-scope="scope" slot="conversation">
            <span v-if="scope.row.conversation == 0">是</span>
            <span v-if="scope.row.conversation == 2">否</span>
          </template>
          <template slot-scope="scope" slot="record">
            <div v-if="scope.row.record.length == 0">暂无录音</div>
            <div v-else>
              <audio
                style="width: 100%; outline: none"
                :src="scope.row.record[0].monitorfilename"
                controls="controls"
              ></audio>
            </div>
          </template>
          <template slot="log" slot-scope="scope">
            <span v-if="scope.row.log.length == 0">暂无图片</span>
            <div v-else>
              <img
                v-for="(item, index) in scope.row.log"
                :src="item.value"
                :key="index"
                style="width: 50px; height: 50px; margin-right: 2px"
              />
            </div>
          </template>
        </avue-crud>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <div
      style="
        background: #dae1e6;
        display: flex;
        padding: 2% 0 2% 20%;
        margin: 2% 0 0 -3% !important;
        position: fixed;
        width: 80%;
        left: 274px;
        bottom: 0;
        z-index: 99;
      "
    >
      <div
        style="
          padding-right: 5%;
          margin-right: 5%;
          border-right: 1px solid white;
        "
      >
        <el-button
          @click="addWechat"
          style="color: red"
          size="medium"
          v-if="taskDetail.wechat == 1"
          >加微信标记(已添加)</el-button
        >
        <el-button type="primary" size="medium" plain @click="addWechat" v-else
          >加微信标记(未添加)</el-button
        >
      </div>
      <div>
        <el-button type="primary" size="medium" plain @click="lookOrigin"
          >查看原始订单</el-button
        >
      </div>
    </div>
    <!-- 查看原始订单 -->
    <el-dialog
      :visible.sync="dialogTableVisibleLook"
      width="70%"
      :close-on-click-modal="false"
    >
      <Detailes2></Detailes2>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["parentRow"],
  data() {
    return {
      //任务详细信息
      taskDetail: {},
      // 投诉售后
      taskDetail1: {},
      imgArr: 0,
      connectRecord: [],
      dealRecord: [],
      option1: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列动态显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          // {
          //   label: "时间",
          //   prop: "th_time",
          //   type: "date",
          //   format: "yyyy-MM-dd hh:mm:ss",
          //   valueFormat: "yyyy-MM-dd hh:mm:ss",
          // },
          {
            label: "处理人",
            prop: "username",
          },
          {
            label: "方式",
            prop: "mode",
          },
          {
            label: "是否接通",
            prop: "conversation",
            slot: true,
          },
          {
            label: "沟通内容",
            prop: "remar",
          },
          {
            label: "沟通录音",
            prop: "record",
            slot: true,
          },
          {
            label: "跟进时间",
            prop: "th_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "下次跟进",
            prop: "end_time",
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
          },
          {
            label: "图片",
            prop: "log",
            type: "upload",
            imgFullscreen: true,
            imgType: "card",
            width: "200px",
            listType: "picture-card",
          },
        ],
      },
      option2: {
        size: "medium",
        menu: false,
        page: false,
        align: "left",
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列动态显隐按钮
        refreshBtn: false, // 刷新按钮
        column: [
          {
            label: "处理时间",
            prop: "creation_time",
          },
          {
            label: "处理人",
            prop: "username",
          },
          {
            label: "电话是否接通",
            prop: "connect",
            slot: true,
          },
          {
            label: "处理是否完成",
            prop: "complete",
            slot: true,
          },
          {
            label: "对处理过程",
            prop: "process",
          },
          {
            label: "对处理结果",
            prop: "result",
          },
          {
            label: "备注",
            prop: "remarks",
          },
        ],
      },
      finishDialog1: false,
      radio1: 2,
      shoppers: [
        {
          label: "阿红",
          value: "01",
        },
        {
          label: "阿橙",
          value: "02",
        },
      ],
      textarea1: "",
      finishDialog2: false,
      orderTime: "",
      form1: {
        reasonArr: [],
        orderTime: "",
        mode: "",
        reason: "",
        remark: "",
      },
      formLabelWidth: "100px",
      form2: {
        customer: "",
      },
      finishDialog3: false,
      options: [],
      defaultParams: {
        label: "title",
        value: "title",
        children: "children",
      },
      form: {
        radio1: "",
        radio2: "",
        radio3: "",
        radio4: "",
        textarea: "",
      },
      formLabelWidth2: "130px",
      finishDialog4: false,
      clients: [],
      dialogTableVisibleLook: false,
      waihudian: { username: "", mobile: "" },
      wiahulema: true,
      wiahulema2: true,
      waihulun: 0,
      isSaveState: true,
      ExternalData: "",
      call: [],
      //  沟通方式
      modes: [
        {
          value: "电话",
          label: "电话",
        },
        {
          value: "微信",
          label: "微信",
        },
        {
          value: "其他",
          label: "其他",
        },
      ],
    };
  },
  mounted() {
    this.getclient();
    this.getDetail();
  },
  methods: {
    // 获取客服
    getclient() {
      this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
        if (res.code == 200) {
          this.clients = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getDetail() {
      //获取任务详情
      this.$loading(this.util.opload);
      this.util
        .get(this.HOST + "/Order/TaskInfo", {
          cc_id: this.parentRow.cc_id,
          order_id: this.parentRow.order_id,
          type: this.parentRow.type,
        })
        .then((res) => {
          if (res.code == 200) {
            this.taskDetail = res.data;
            this.imgArr = res.data.data[0].picture.length;
            this.taskDetail1 = res.data.data[0];
            //console.log(res.data.data[0]);
            this.connectRecord = res.data.through;
            this.dealRecord = res.data.result;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    lookOrigin() {
      this.dialogTableVisibleLook = true;
    },
    addWechat() {
      this.$loading(this.util.opload);
      let wechat = "";
      if (this.taskDetail.wechat == 1) {
        wechat = 0;
      } else {
        wechat = 1;
      }
      this.util
        .get(this.HOST + "/Order/SelectorSwitch", {
          order_id: this.parentRow.order_id,
          wechat: wechat,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              showClose: true,
              message: "处理成功!",
              type: "success",
              duration: 1200,
              offset: 90,
            });
            this.getDetail();
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
      this.getDetail();
    },
    finishCancel() {},
    openFinish() {
      this.finishDialog1 = true;
    },
    openSetting() {
      this.finishDialog2 = true;
    },
    openDeliver() {
      this.finishDialog3 = true;
    },
    handleChange() {},
    recoveryOrder() {
      this.finishDialog4 = true;
    },
  },
  components: {
    Detailes2: () => import("@/components/detailes2.vue"),
  },
};
</script>
<style scoped>
.totalPr {
  background: white !important;
  padding-left: 25px;
  width: 96% !important;
}
.container {
  margin: 0 auto;
  background: white;
  padding: 40px 0 0 80px;
  border-radius: 8px;
  /* height: 100%; */
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.abs {
  position: absolute;
}
.wuyekuang {
  top: 37px;
  left: 76px;
  width: 202px;
  list-style: none;
  z-index: 20000;
  overflow: hidden;
  max-height: 143px;
  padding: 6px 0;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border: 1px solid #e4e7ed;
  color: #666;
}
.zanwu {
  padding: 10px 0;
  margin: 0;
  text-align: center;
  color: #999;
  font-size: 15px;
  top: 50px;
  left: 76px;
  width: 202px;
  box-sizing: border-box;
  background: white;
  border: 1px solid #e4e7ed;
  position: absolute;
  z-index: 999;
  cursor: pointer;
}
.box {
  padding: 30px 0 0 30px;
  background: white;
}
.prCon {
  display: flex;
  flex-wrap: wrap;
}
.childCon {
  width: 16%;
  margin-bottom: 40px;
}
.childCon2 {
  width: 32%;
  margin-bottom: 40px;
}
.childCon3 {
  width: 100%;
  margin-bottom: 20px;
}
.childCon4 {
  width: 24%;
  margin-bottom: 60px;
}
.childCon5 {
  width: 48%;
  margin-bottom: 60px;
}
.childCon6 {
  width: 24%;
  margin-bottom: 20px;
}
.childCon7 {
  width: 20%;
  margin-bottom: 20px;
}
.childCon8 {
  width: 60%;
  margin-bottom: 20px;
}
.childMt {
  margin-right: 8px;
}
.btnStyle {
  border: 1px solid #409eff;
  color: #409eff;
}
.avue-crud__menu {
  min-height: 4px !important;
}
.casStyle {
  width: 300px !important;
}
</style>
